<template>
  <v-app>
    <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-alert v-model="success" type="success" dismissible>
        {{dialogText}}
      </v-alert>
      <v-alert v-model="error" type="error" dismissible>
        {{dialogText}}
      </v-alert>
      <v-alert v-model="errorDb" type="error" dismissible>
        {{dialogTextDb}}
      </v-alert>
      <v-card>
        <v-card-title class="indigo white--text text-h5">
          ユーザ設定
        </v-card-title>
        <v-row class="pa-4" justify="space-between">
        
          <!-- 画面左部ツリー -->
          <v-col cols="5">
            <v-treeview
              activatable
              :items="items"
              :active.sync="active"
              :open.sync="open"
              open-on-click
            >
            </v-treeview>
          </v-col>
          <!-- 画面左部ツリー -->
          
          <v-divider vertical></v-divider>
          
          <!-- 画面右部 -->
          <v-col class="d-flex text-center">
            <v-scroll-y-transition mode="out-in">
              <!--未選択-->
              <div
                v-if="!selected"
                class="text-h6 text--lighten-1 font-weight-light"
                style="align-self: center;"
              >
                操作内容を選択して下さい。
              </div>
              <!--選択中-->
              <v-card
                v-else
                :key="selected.id"
                class="mx-auto"
                flat
                width="95%"
              >
                <div v-if="editpwd">
                  <v-text-field style="margin:25px 25px 0px 25px" v-model="oldpassword" label="旧パスワード" :rules="[passRules.required, passRules.min]">
                  </v-text-field>
                  <v-text-field style="margin:0px 25px" v-model="newpassword" label="新パスワード" :rules="[passRules.required, passRules.min]">
                  </v-text-field>
                  <v-text-field style="margin:0px 25px 25px 25px" v-model="newpasswordcheck" label="新パスワード（確認）" :rules="[passRules.required, passRules.min]">
                  </v-text-field>
                </div>
                <div v-else-if="editmail">
                  <v-card-text class="text-left">
                    メールアドレス：{{authmailaddress}}
                    <v-chip v-if="mailauth" small color="success" text-color="white">認証済</v-chip>
                    <v-chip v-else small color="red" text-color="white">未認証</v-chip>
                  </v-card-text>
                  <v-text-field style="margin:25px 25px 0px 25px" v-model="mailaddress" v-bind:disabled="isCodeIssue" label="新メールアドレス" :rules="[mailRules.required, mailRules.format]">
                  </v-text-field>
                  <v-btn small style="font-size:16px; margin:0px 25px" v-on:click.stop="updateEmail()" v-bind:disabled="isSetCode" color="primary">メールアドレス更新</v-btn>
                  <v-btn small style="font-size:16px; margin:0px 25px" v-on:click.stop="resendConfirmCode(true)" v-bind:disabled="!isCodeIssue" color="primary">確認コード発行</v-btn>
                  <v-text-field style="margin:0px 25px" v-model="confirmcode" label="確認コード" :rules="itemRules">
                  </v-text-field>
                </div>
                <div v-else-if="editerrmail">
                  <v-switch v-model="mailenabled" :label="`アラームメール送信：${enabledString}`" @change="changeEnabled(mailenabled)">
                  </v-switch>
                </div>
                <!--ボタン部-->
                <v-row class="pa-4">
                  <v-spacer></v-spacer>
                  <v-btn small v-bind:disabled="isSet" v-on:click.stop="setting()" color="primary" style="font-size:16px" disable>設定</v-btn>
                </v-row>
              </v-card>
              
            </v-scroll-y-transition>
          </v-col>

        </v-row>
      </v-card>
      <v-row class="pa-4">
      </v-row>
    </v-form>
    </v-container>
  </v-app>
</template>
<script>
  import {getUser, changePassword, updateEmail, emailSubmit, resendCode, getGlobalUser} from '@/js/auth.js'
  
  export default {
    data: () => ({
      //表示・編集用ユーザ情報
      id:null,
      
      active: [],
      open: [],
      items: [
        {id:'0', name:'ユーザーパスワード変更'},
        {id:'1', name:'メールアドレス変更'},
        {id:'2', name:'アラームメール送信'}],
      
      hosturl: process.env.VUE_APP_APIURL,
      
      dialogText:null,
      dialogTextDb:null,
      
      success:false,
      error:false,
      errorDb:false,
      
      
      valid: true,
      
      loginuser: null,
      
      editpwd: false,
      editmail: false,
      editerrmail: false,
      mailenabled:false,
      
      oldpassword:'',
      newpassword:'',
      newpasswordcheck:'',
      
      mailaddress:'',
      confirmcode:'',
      mailauth:null,
      authmailaddress:null,
      
      isSet: true,
      isSetCode:true,
      isCodeIssue:false,
      enabledString:'無効',

    }),
    computed: {
      selected () {
        if (!this.active.length) return undefined

        const activeid = this.active[0]
        var action = this.items.find(item => item.id === activeid)
        
        this.setdata(action);
        
        return action
      },
      passRules() {
        return {
          required: v => !!v || '必須項目です。',
          min: v => v.length >= 8 || '8文字以上入力してください。',
        }
      },
      itemRules(){
        return [
          v => !!v || '必須項目です。'
        ]
      },
      mailRules() {
        return {
          required: v => !!v || '必須項目です。',
          format: v => /.+@.+/.test(v) || 'フォーマットが正しくありません。'
        }
      },
    },
    methods: {
      //操作内容選択
      setdata: function(action){
        console.log(action)
        
        this.oldpassword = ''
        this.newpassword = ''
        this.newpasswordcheck = ''
        
        this.mailaddress = ''
        this.confirmcode = ''
        this.isCodeIssue = false
        
        if(action.id === "0"){
          this.editpwd = true
          this.editmail = false
          this.editerrmail = false
          this.isSet = true
        } else if(action.id === "1") {
          this.editpwd = false
          this.editmail = true
          this.editerrmail = false
          this.isSet = true
          this.getUserDetail();
        } else {
          this.editpwd = false
          this.editmail = false
          this.editerrmail = true
          this.isSet = false
        }
        this.id = action.id
      },
      // ユーザ情報取得(Cognito)
      getUserDetail:function(){
        getUser().then((user) => {
          if (user) {
            getGlobalUser().then(result => {
              if(result){
                this.$axios.defaults.headers.common['Authorization'] = user.signInUserSession.idToken.jwtToken;
                var usermailState = result.attributes;
                this.authmailaddress = usermailState.email;
                this.mailauth = usermailState.email_verified;
              }
            })
          }
        })
        .catch((err) => {
          console.log(err);
          this.dialogText = 'ユーザ情報の取得に失敗しました。';
          this.success = false;
          this.error = true;
          this.returnTop('auto');
        });
      },
      //ユーザ情報設定
      setting: async function(){
        if(this.$refs.form.validate()){
          if(this.editpwd){
            if(this.newpassword === this.newpasswordcheck){
              var res = await changePassword(this.loginuser, this.oldpassword, this.newpassword)
              this.active = [];
              if(res){
                console.log(res)
                this.dialogText = 'パスワードの変更に失敗しました。【エラーコード：' + res["code"] + '】';
                this.success = false;
                this.error = true;
              } else {
                this.dialogText = 'パスワードを変更しました。';
                this.success = true;
                this.error = false;
              }
              this.oldpassword = '';
              this.newpassword = '';
              this.newpasswordcheck = '';
            } else {
              this.dialogText = '新パスワードと新パスワード（確認）が一致していません。';
              this.success = false;
              this.error = true;
              this.newpassword = '';
              this.newpasswordcheck = '';
            }
            this.returnTop('auto');
          }
          else if(this.editmail){
            var res2 = await emailSubmit(this.confirmcode)
            this.active = [];
            if(res2){
              console.log(res2)
              this.dialogText = 'メールアドレスの変更に失敗しました。【エラーコード：' + res["code"] + '】';
              this.success = false;
              this.error = true;
            } else {
              this.dialogText = 'メールアドレスを変更しました。認証ステータスを確認してください。';
              this.success = true;
              this.error = false;
            }
            this.returnTop('auto');
          }
          else if(this.editerrmail){
            this.updateErrMailEnabled(this.mailenabled)
          }
        }
      },
      
      updateErrMailEnabled: function(mailenabled){
        var apiurl = this.hosturl + "/users/" + this.loginuser.username + "?mailenabled=" + mailenabled
        var param = {
           "mail_enabled": mailenabled
        }
        this.active = [];
        this.$axios.post(apiurl, param).then(res => {
          console.log(res)
          this.dialogText = 'アラームメール送信設定を更新しました。';
          this.success = true;
          this.error = false;
          
          var updateUser = this.$store.state.loginuser;
          updateUser['mail_enabled'] = mailenabled
          this.$store.commit('setLoginUser', updateUser);
          
          this.returnTop('auto');
        })
        .catch((err) => {
          console.log(err);
          this.dialogText = 'アラームメール送信設定の更新に失敗しました。';
          this.success = false;
          this.error = true;
          this.returnTop('auto');
        });
      },
      updateEmail: async function(){
        var res = await updateEmail(this.loginuser, this.mailaddress)
        if(res){
          console.log(res)
          this.dialogText = 'メールアドレスの更新に失敗しました。【エラーコード：' + res["code"] + '】';
          this.success = false;
          this.error = true;
        } else {
          this.resendConfirmCode(false)
          this.getUserDetail();
          this.isCodeIssue = true;
          this.isSetCode = true;
          
          console.log(this.loginuser)
          var apiurl = this.hosturl + "/users/" + this.loginuser.username
          this.$axios.get(apiurl).then(res => {
            var param = {
              "mailaddress": this.mailaddress,
              "role": res.data[this.loginuser.username]['roletype'],
              "infotime": res.data[this.loginuser.username]['ioformation_confirmed_time'],
              "delflg": res.data[this.loginuser.username]['delflg']
            }
            
            this.$axios.post(apiurl, param).then(res => {
              console.log(res)
            })
            .catch((err) => {
              console.log(err);
              this.dialogTextDb = 'データベースへの反映に失敗しました。'
              this.errorDb = true;
              this.returnTop('smooth');
              return
            });
          }, this)
          .catch((err) => {
            console.log(err);
            this.dialogText = 'ユーザ情報の取得に失敗しました。';
            this.success = false;
            this.error = true;
            this.returnTop('auto');
          });
        }
        this.returnTop('auto');
      },
      resendConfirmCode: async function(msgflg){
        var res = await resendCode()
        if(res){
          console.log(res)
          if(msgflg){
            this.dialogText = '確認コードの発行に失敗しました。【エラーコード：' + res["code"] + '】';
            this.success = false;
            this.error = true;
          }else{
            this.dialogText = 'メールアドレスは更新しましたが確認コードの発行に失敗しました。【エラーコード：' + res["code"] + '】';
            this.success = false;
            this.error = true;
          }
        } else {
          if(msgflg){
            this.dialogText = '確認コードを発行しました。';
            this.success = true;
            this.error = false;
          }else{
            this.dialogText = 'メールアドレスを更新しました。続けて確認コードを入力してください。';
            this.success = true;
            this.error = false;
          }
          this.isCodeIssue = true;
          this.isSetCode = true;
        }
        this.returnTop('auto');
      },
      changeEnabled: function(enabled){
        if (enabled){
          this.enabledString ="有効"
        }
        else{
          this.enabledString ="無効"
        }
      },
      returnTop(action) {
        window.scrollTo({
          top: 0,
          behavior: action
        })
      },
    },
    mounted() {
      this.loginuser = this.$store.state.user;
      if(this.$store.state.loginuser['mail_enabled']){
        this.mailenabled = this.$store.state.loginuser['mail_enabled']
        this.changeEnabled(this.$store.state.loginuser['mail_enabled'])
      }
    },
    watch: {
      oldpassword: function (newValue) {
        if(newValue && this.newpassword && this.newpasswordcheck && this.editpwd){
          this.isSet = false;
        } else {
          this.isSet = true;
        }
      },
      newpassword: function (newValue) {
        if(newValue && this.oldpassword && this.newpasswordcheck && this.editpwd){
          this.isSet = false;
        } else {
          this.isSet = true;
        }
      },
      newpasswordcheck: function (newValue) {
        if(newValue && this.newpassword && this.oldpassword && this.editpwd){
          this.isSet = false;
        } else {
          this.isSet = true;
        }
      },
      mailaddress: function (newValue) {
        if(newValue){
          this.isSetCode = false;
        } else {
          this.isSetCode = true
        }
        if(newValue && this.confirmcode && this.isCodeIssue && this.editmail){
          this.isSet = false;
        } else {
          this.isSet = true;
        }
      },
      confirmcode: function (newValue) {
        if(newValue && this.mailaddress && this.isCodeIssue && this.editmail){
          this.isSet = false;
        } else {
          this.isSet = true;
        }
      },
    }
  }
</script>